
.level {
  margin-bottom: 0;
}

.result-row:nth-child(even) .column {
  background-color: #f8f8f8;
  border-radius: 5px;
}

.group-dashboard-overview {
  .inner {
    width: 100%;
  }
  .dashboard-overview-inner {
    width: 100%;
    max-width: 100%;
    overflow-x: scroll;
    white-space: nowrap;
  }
  .arrow-left {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-20%, -50%);
    z-index: 20;
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 100%;
    opacity: 0.6;
  }

  .arrow-right {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(20%, -50%);
    z-index: 20;
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 100%;
    opacity: 0.6;
  }

  position: relative;
  .member {
    width: 60px;
    display: inline-flex;
    height: 250px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin-left: 10px;
    .bar {
      flex: 1;
      width: 100%;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .inner {
        border: 2px solid #e3e3e3;
        position: relative;
        border-radius: 20px;
        overflow: hidden;
        width: 40px;
        flex: 1;
        background-color: #f8f9fb;
        .progress {
          position: absolute;
          bottom: 0;
          background-color: #1d73f4;
          width: 100%;
          border-radius: 0;
          transition: height 0.3s ease-in-out;
        }
      }
    }
    .user {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .profile-pic {
        width: 35px;
        height: 35px;
        overflow: hidden;
        border-radius: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
